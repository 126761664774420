import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '../../components/Typography';
import Button from '../../components/inputs/Button';
import useStyles from './style';
import logo from '../../utils/Logo/Logo-White-500.png'
import Logo from '../../utils/Logo/SNOVIO.svg'
const Splash = () => {
    const classes = useStyles();
    const history = useHistory()
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <img src={logo} alt="logo" />
                <img src={Logo} alt="logo" />
            </div>
            <div className={classes.titles}>

                <Typography className={classes.title}>Gerador de Cold e-mail</Typography>
                <Typography className={classes.subtitle}>Crie, de maneira rápida e fácil, uma campanha
                    usando nossos templates de cold e-mail.</Typography>

                <Button onClick={() => {
                    history.push('/access')
                }} className={classes.button}>COMEÇAR</Button>
            </div>

        </div>
    );
};
export default Splash;
