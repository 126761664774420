import { makeStyles } from '@material-ui/core/styles';
import image from '../../utils/background/laranja.png'
const useStyles = makeStyles((theme: any) => ({
    main: {
        backgroundColor: '#2a2a2a',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            alignItems: 'flex-start',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        "& img": {
            width: '110px',
            height: '30px',
            marginTop: '43px',
        },
        "& img:nth-child(1)": {
            marginRight: '30px'
        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            "& img": {
                width: '150px',
                height: '37px',
                marginTop: '43px',
            },
            "& img:nth-child(1)": {
                marginLeft: '30px',
                marginRight: '40px'
            },
        }
    },
    titles: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '25vh',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            marginTop: '138px',
        }
    },
    title: {
        maxWidth: '400px',
        fontSize: '34px',
        lineHeight: '43px',
        margin: "0px 30px 13px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '30px',
            maxWidth: '360px',
            lineHeight: '38px',
        }
    },
    subtitle: {
        maxWidth: '400px',
        fontSize: '16px',
        margin: "0px 30px 20px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '14px',
            maxWidth: '360px',
            lineHeight: '18px',
        }
    },
    button: {
        width: '231px',
        height: '38px',
        background: theme.palette.color1,
        "&:hover": {
            background: theme.palette.color1,
        },
    }

}));

export default useStyles;
