import { makeStyles } from '@material-ui/core/styles';
import image from '../../utils/background/laranja.png'
const useStyles = makeStyles((theme: any) => ({
    main: {
        backgroundColor: '#2a2a2a',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            alignItems: 'flex-start',
            height: '100vh',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        "& img": {
            width: '110px',
            height: '30px',
            marginTop: '43px',
        },
        "& img:nth-child(1)": {
            marginRight: '30px'
        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            "& img": {
                width: '150px',
                height: '37px',
                marginTop: '43px',
            },
            "& img:nth-child(1)": {
                marginLeft: '30px',
                marginRight: '40px'
            },
        }
    }, titles: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '15vh',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            marginTop: '138px',
        }

    },
    title: {
        maxWidth: '400px',
        fontSize: '34px',
        lineHeight: '43px',
        margin: "0px 30px 13px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '30px',
            maxWidth: '360px',
            lineHeight: '38px',
        }
    },
    subtitle: {
        maxWidth: '240px',
        fontSize: '16px',
        margin: "0px 30px 20px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '14px',
            maxWidth: '400px',
            lineHeight: '18px',
        }
    },
    buttons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
        "& Button:nth-child(2)": {
            marginBotton: "0px"
        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            flexDirection: 'row',
            justifyContent: 'center'
        }
    },
    button: {
        width: '123px',
        height: '123px',
        display: 'flex',
        color: 'rgba(34, 34, 35, 1)',
        borderRadius: '20px',
        justifyContent: 'center',
        alignContent: 'center',
        margin: '0px 0px 30px 0px',
        backgroundColor: theme.palette.color3,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: 300,
        padding: '0px',
        "&:hover": {
            backgroundColor: 'white',
        }
        ,
        "&:active": {
            backgroundColor: 'white',
        },
        "& img": {
            width: '90px',
            height: '77px',



        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            margin: '20px 20px 0px 20px',
        }
    },
    buttonDisabled: {
        width: '123px',
        height: '123px',
        display: 'flex',
        color: 'rgba(34, 34, 35, 1)',
        borderRadius: '20px',
        justifyContent: 'center',
        alignContent: 'center',
        margin: '0px 0px 30px 0px',
        backgroundColor: '#646465',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: 300,
        padding: '0px',
        "&:hover": {
            backgroundColor: '#646465',
        },
        "& img": {
            width: '90px',
            height: '77px',



        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            margin: '20px 20px 0px 20px',
        }
    }
}));

export default useStyles;
