import React from "react";

import { Typography as MaterialTypography } from "@material-ui/core";

import useStyles from "./style";

const Typography = (props: any) => {
    const styleParams = {
        type: props?.type,
    };
    const classes = useStyles(styleParams);
    return (
        <>
            <MaterialTypography
                display={props.display}
                variant={props.variant}
                align={props.align}
                color={props.color}
                style={props.style}
                noWrap={props.noWrap}
                component={props.component}
                gutterBottom={props.gutterBottom}
                className={`${classes.typography} ${props?.type ?? ""} ${props.className ?? ""
                    }`}
            >
                {props.children}
            </MaterialTypography>
        </>
    );
};

export default Typography;
