import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '../../components/Typography';
import Button from '../../components/inputs/Button';
import useStyles from './style';
import axios from "axios";
import logo from '../../utils/Logo/Logo-White-500.png'
import Logo from '../../utils/Logo/SNOVIO.svg'

const Access = () => {
    const history = useHistory()
    const [data, setData] = useState({
        nome: '',
        email: '',
        empresa: ''
    })
    const handleClick = () => {
        axios.request({
            method: 'POST',
            url: `${process.env.REACT_APP_REQUEST_URL}sync`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                contact: {
                    email: data.email,
                    nome: data.nome.split(" ")[0],
                    sobrenome: data.nome.split(" ")[1],
                    fieldValues: [{ field: '38', value: data.empresa }]
                }
            }
        }).then(function (response) {
            axios.request({
                method: 'POST',
                url: `${process.env.REACT_APP_REQUEST_URL}tag`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { contactTag: { contact: response.data.contact.id, tag: 1068 } }
            }).then(function (resp) {
                history.push({
                    pathname: '/options',
                    state: { ...response.data }
                })
            }).catch(function (error) {
                console.error(error);
            });

        }).catch(function (error) {
            console.error(error);
        });
    }
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <img src={logo} alt="logo" />
                <img src={Logo} alt="logo" />
            </div>
            <div className={classes.titles}>
                <Typography className={classes.title}>Gerador de Cold e-mail</Typography>
                <Typography className={classes.subtitle}>Preencha os campos abaixo
                    para acessar os templates</Typography>
                <div className={classes.form}>
                    <input className={classes.input} onChange={(e: any) => {
                        setData({
                            ...data,
                            nome: e.target.value
                        })
                    }} placeholder="QUAL O SEU NOME?" />
                    <input className={classes.input} onChange={(e: any) => {
                        setData({
                            ...data,
                            email: e.target.value
                        })
                    }} placeholder="QUAL O EMAIL QUE VOCÊ MAIS ACESSA?" />
                    <input className={classes.input} onChange={(e: any) => {
                        setData({
                            ...data,
                            empresa: e.target.value
                        })
                    }} placeholder="QUAL O NOME DA SUA EMPRESA?" />
                    <Button onClick={handleClick} className={classes.button}>ENVIAR</Button>
                </div>
            </div>

        </div>
    );
};
export default Access;
