import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import Typography from "../../components/Typography";
import Button from "../../components/inputs/Button";
import Select from "../../components/inputs/Select";
import useStyles from "./style";
import logo from "../../utils/Logo/Logo-White-500.png";
import Logo from "../../utils/Logo/SNOVIO.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
const Create = () => {
  const classes = useStyles();
  const history = useHistory();
  const [emails, setEmails]: any = useState({
    primeiroContato: [],
    segundoContato: [],
    terceiroContato: [],
    quartoContato: [],
    quintoContato: [],
  });

  const [selectedEmails, setSelectedEmails] = useState({
    primeiroContato: {
      assunto: "",
      corpo: "",
    },
    segundoContato: {
      assunto: "",
      corpo: "",
    },
    terceiroContato: {
      assunto: "",
      corpo: "",
    },
    quartoContato: {
      assunto: "",
      corpo: "",
    },
    quintoContato: {
      assunto: "",
      corpo: "",
    },
  });
  const data: any = history.location;
  const handleSubmit = () => {
    const data: any = history.location.state;
    axios
      .request({
        method: "POST",
        url: `${process.env.REACT_APP_REQUEST_URL}sync`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          contact: {
            email: data.data.contact.email,
            nome: data.data.contact.firstName,
            fieldValues: [
              {
                field: "87",
                value: selectedEmails.primeiroContato.corpo,
              },
              {
                field: "86",
                value: selectedEmails.primeiroContato.assunto,
              },
              {
                field: "88",
                value: selectedEmails.segundoContato.assunto,
              },
              {
                field: "89",
                value: selectedEmails.segundoContato.corpo,
              },
              {
                field: "90",
                value: selectedEmails.terceiroContato.assunto,
              },
              {
                field: "91",
                value: selectedEmails.terceiroContato.corpo,
              },
              {
                field: "92",
                value: selectedEmails.quartoContato.assunto,
              },
              {
                field: "93",
                value: selectedEmails.quartoContato.corpo,
              },
              {
                field: "94",
                value: selectedEmails.quintoContato.assunto,
              },
              {
                field: "95",
                value: selectedEmails.quintoContato.corpo,
              },
            ],
          },
        },
      })
      .then(function (response) {
        axios
          .request({
            method: "POST",
            url: `${process.env.REACT_APP_REQUEST_URL}tag`,
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              contactTag: { contact: response.data.contact.id, tag: 1070 },
            },
          })
          .then(function (response) {
            window.location.href =
              "https://lp.growthmachine.com.br/gerador-de-cold-mail/tk/";
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_REQUEST_URL}template/${data.state.select}`,
      })
      .then(function (response) {
        setEmails({
          primeiroContato: response.data.primeiroContato,
          segundoContato: response.data.segundoContato,
          terceiroContato: response.data.terceiroContato,
          quartoContato: response.data.quartoContato,
          quintoContato: response.data.quintoContato,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [data.state.select]);

  const getTargetName = () => {   
    switch (data.state.select) {
      case 1:
          return "Seus alvos são os Gerentes."
      case 2:
          return "Seus alvos são os CEOs."
      default:
          return "Seu alvo é Marketing."
  }
  }

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <img src={logo} alt="logo" />
        <img src={Logo} alt="logo" />
      </div>
      <div className={classes.titles}>
        <Typography className={classes.title}>Crie sua campanha!</Typography>
        <Typography className={classes.subtitle}>
          Ótimo! {getTargetName()}
        </Typography>
        <Typography className={classes.subtitle}>
          Escolha entre as listas abaixo as opções que se encaixam melhor nos
          seus objetivos
        </Typography>
      </div>

      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.select}>
            <Select
              onChange={(value: any) => {
                setSelectedEmails({
                  ...selectedEmails,
                  primeiroContato: {
                    assunto: emails.primeiroContato[value]?.Assunto,
                    corpo: emails.primeiroContato[value]?.Corpo,
                  },
                });
              }}
              options={[
                ...emails.primeiroContato.map((email: any, index: any) => {
                  return {
                    value: index,
                    label: email.Assunto,
                  };
                }),
              ]}
            />
          </div>
          <input
            value={selectedEmails.primeiroContato.assunto}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                primeiroContato: {
                  assunto: e.target.value,
                  corpo: selectedEmails.primeiroContato.corpo,
                },
              });
            }}
            className={classes.input}
          />
          <textarea
            value={selectedEmails.primeiroContato.corpo}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                primeiroContato: {
                  assunto: selectedEmails.primeiroContato.assunto,
                  corpo: e.target.value,
                },
              });
            }}
            className={classes.textarea}
          />
          <ScrollLink to="second" smooth={true}>
            {" "}
            <Button className={classes.button}> OK! Próximo</Button>
          </ScrollLink>
        </div>
      </div>
      <div
        className={
          selectedEmails.primeiroContato.corpo === ""
            ? classes.disabled
            : classes.container
        }
        id="second"
      >
        <div className={classes.content}>
          <div className={classes.select}>
            <Select
              onChange={(value: any) => {
                setSelectedEmails({
                  ...selectedEmails,
                  segundoContato: {
                    assunto: emails.segundoContato[value]?.Assunto,
                    corpo: emails.segundoContato[value]?.Corpo,
                  },
                });
              }}
              options={[
                ...emails.segundoContato.map((email: any, index: any) => {
                  return {
                    value: index,
                    label: email.Assunto,
                  };
                }),
              ]}
            />
          </div>
          <input
            value={selectedEmails.segundoContato.assunto}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                segundoContato: {
                  assunto: e.target.value,
                  corpo: selectedEmails.segundoContato.corpo,
                },
              });
            }}
            className={classes.input}
          />
          <textarea
            value={selectedEmails.segundoContato.corpo}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                segundoContato: {
                  assunto: selectedEmails.segundoContato.assunto,
                  corpo: e.target.value,
                },
              });
            }}
            className={classes.textarea}
          />
          <ScrollLink to="third" smooth={true}>
            {" "}
            <Button className={classes.button}> OK! Próximo</Button>
          </ScrollLink>
        </div>
      </div>
      <div
        className={
          selectedEmails.segundoContato.corpo === ""
            ? classes.disabled
            : classes.container
        }
        id="third"
      >
        <div className={classes.content}>
          <div className={classes.select}>
            <Select
              onChange={(value: any) => {
                setSelectedEmails({
                  ...selectedEmails,
                  terceiroContato: {
                    assunto: emails.terceiroContato[value]?.Assunto,
                    corpo: emails.terceiroContato[value]?.Corpo,
                  },
                });
              }}
              options={[
                ...emails.terceiroContato.map((email: any, index: any) => {
                  return {
                    value: index,
                    label: email.Assunto,
                  };
                }),
              ]}
            />
          </div>
          <input
            value={selectedEmails.terceiroContato.assunto}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                terceiroContato: {
                  assunto: e.target.value,
                  corpo: selectedEmails.terceiroContato.corpo,
                },
              });
            }}
            className={classes.input}
          />
          <textarea
            value={selectedEmails.terceiroContato.corpo}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                terceiroContato: {
                  assunto: selectedEmails.terceiroContato.assunto,
                  corpo: e.target.value,
                },
              });
            }}
            className={classes.textarea}
          />
          <ScrollLink to="fourth" smooth={true}>
            {" "}
            <Button className={classes.button}> OK! Próximo</Button>
          </ScrollLink>
        </div>
      </div>
      <div
        className={
          selectedEmails.terceiroContato.corpo === ""
            ? classes.disabled
            : classes.container
        }
        id="fourth"
      >
        <div className={classes.content}>
          <div className={classes.select}>
            <Select
              onChange={(value: any) => {
                setSelectedEmails({
                  ...selectedEmails,
                  quartoContato: {
                    assunto: emails.quartoContato[value]?.Assunto,
                    corpo: emails.quartoContato[value]?.Corpo,
                  },
                });
              }}
              options={[
                ...emails.quartoContato.map((email: any, index: any) => {
                  return {
                    value: index,
                    label: email.Assunto,
                  };
                }),
              ]}
            />
          </div>
          <input
            value={selectedEmails.quartoContato.assunto}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                quartoContato: {
                  assunto: e.target.value,
                  corpo: selectedEmails.quartoContato.corpo,
                },
              });
            }}
            className={classes.input}
          />
          <textarea
            value={selectedEmails.quartoContato.corpo}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                quartoContato: {
                  assunto: selectedEmails.quartoContato.assunto,
                  corpo: e.target.value,
                },
              });
            }}
            className={classes.textarea}
          />
          <ScrollLink to="fourth" smooth={true}>
            {" "}
            <Button className={classes.button}> OK! Próximo</Button>
          </ScrollLink>
        </div>
      </div>
      <div
        className={
          selectedEmails.quartoContato.corpo === ""
            ? classes.disabled
            : classes.container
        }
        id="fifth"
      >
        <div className={classes.content}>
          <div className={classes.select}>
            <Select
              onChange={(value: any) => {
                setSelectedEmails({
                  ...selectedEmails,
                  quintoContato: {
                    assunto: emails.quintoContato[value]?.Assunto,
                    corpo: emails.quintoContato[value]?.Corpo,
                  },
                });
              }}
              options={[
                ...emails.quintoContato.map((email: any, index: any) => {
                  return {
                    value: index,
                    label: email.Assunto,
                  };
                }),
              ]}
            />
          </div>
          <input
            value={selectedEmails.quintoContato.assunto}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                quintoContato: {
                  assunto: e.target.value,
                  corpo: selectedEmails.quintoContato.corpo,
                },
              });
            }}
            className={classes.input}
          />
          <textarea
            value={selectedEmails.quintoContato.corpo}
            onChange={(e) => {
              setSelectedEmails({
                ...selectedEmails,
                quintoContato: {
                  assunto: selectedEmails.quintoContato.assunto,
                  corpo: e.target.value,
                },
              });
            }}
            className={classes.textarea}
          />
          <ScrollLink to="fim" smooth={true}>
            {" "}
            <Button className={classes.button}> OK! Próximo</Button>
          </ScrollLink>
        </div>
      </div>
      {selectedEmails.primeiroContato.assunto !== "" &&
        selectedEmails.segundoContato.assunto !== "" &&
        selectedEmails.terceiroContato.assunto !== "" &&
        selectedEmails.quartoContato.assunto !== "" &&
        selectedEmails.quintoContato.assunto !== "" && (
          <div className={classes.container}>
            <hr
              style={{
                width: "40%",
                marginTop: "95px",
                background: "black",
                marginBottom: "20px",
              }}
            />
            <div id="fim">
              <div className={classes.secondTitle}>
                <Typography
                  className={classes.subtitle}
                  style={{ color: "rgba(255,255,255,1)", fontSize: "16px" }}
                >
                  Parabéns!
                </Typography>
                <Typography
                  className={classes.subtitle}
                  style={{ color: "rgba(255,255,255,.6)", fontSize: "16px" }}
                >
                  Você acabou de criar uma cadência! Não foi tão difícil, foi?
                </Typography>
                <Typography
                  className={classes.subtitle}
                  style={{ color: "rgba(255,255,255,.6)", fontSize: "16px" }}
                >
                  Você quer receber esses templates no seu email?
                </Typography>
                <Button
                  className={classes.button}
                  style={{ width: "100%", alignSelf: "center", margin: "0px" }}
                  onClick={() => {
                    // window.location.href =
                    //   "https://lp.growthmachine.com.br/mais-clientes-em-21-dias/tk/";
                    handleSubmit();
                  }}
                >
                  SIM, ENVIE PARA MEU EMAIL
                </Button>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
export default Create;
