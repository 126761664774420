import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    typography: {
        color: theme.palette.textColor1,
        fontSize: theme.typography.fontSize,

        "&.title": {
            letterSpacing: 0,
            fontSize: theme.typography.title.fontSize,
            fontWeight: theme.typography.fontWeightBold,
        },
        "&.subtitle": {
            letterSpacing: 0,
            fontSize: theme.typography.subtitle.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
}));

export default useStyles;
