import React from 'react';
import Typography from '../../components/Typography';
import Button from '../../components/inputs/Button';
import useStyles from './style';
import logo from '../../utils/Logo/Logo-White-500.png'
import Logo from '../../utils/Logo/SNOVIO.svg'
import { useHistory } from 'react-router-dom';
import CEO from '../../utils/icons GM/CEO.svg'

import GERENTES from '../../utils/icons GM/GERENTES.svg'

import MKT from '../../utils/icons GM/MKT.svg'
const Options = () => {
    const classes = useStyles();
    const history = useHistory();
    const data = history.location.state
    const handleClick = (type: any) => {
        history.push({
            pathname: '/create',
            state: {
                data,
                select: type
            }
        })
    }
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <img src={logo} alt="logo" />
                <img src={Logo} alt="logo" />
            </div>
            <div className={classes.titles}>
                <Typography className={classes.title}>Escolha uma persona</Typography>
                <Typography className={classes.subtitle}>O alvo da minha campanha é...</Typography>
            </div>
            <div className={classes.buttons}>
                <Button onClick={() => { handleClick(2) }} className={classes.button}><img src={CEO} alt="ceo img" />CEOs</Button>
                <Button onClick={() => { handleClick(1) }} className={classes.button}><img src={GERENTES} alt="gerente img" />Gerentes</Button>
                <Button onClick={() => { handleClick(3) }} className={classes.button}><img src={MKT} alt="marketing img" /> Marketing</Button>
            </div>


        </div>
    );
};
export default Options;
