import { makeStyles } from '@material-ui/core/styles';
import image from '../../utils/background/laranja.png'
const useStyles = makeStyles((theme: any) => ({
    main: {
        background: '#2a2a2a',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            alignItems: 'flex-start',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        "& img": {
            width: '110px',
            height: '30px',
            marginTop: '43px',
        },
        "& img:nth-child(1)": {
            marginRight: '30px'
        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            "& img": {
                width: '150px',
                height: '37px',
                marginTop: '43px',
            },
            "& img:nth-child(1)": {
                marginLeft: '30px',
                marginRight: '40px'
            },
        }
    },
    titles: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '15vh',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            marginTop: '15vh',
        }

    },
    title: {
        maxWidth: '400px',
        fontSize: '34px',
        lineHeight: '43px',
        margin: "0px 30px 13px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '30px',
            maxWidth: '360px',
            lineHeight: '38px',
        }
    },
    subtitle: {
        maxWidth: '240px',
        fontSize: '16px',
        margin: "0px 30px 20px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '14px',
            maxWidth: '400px',
            lineHeight: '18px',
        }
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            width: '330px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    input: {
        border: 'none',
        marginBottom: '10px',
        width: '80%',
        height: '31px',
        fontSize: '12px',
        textAlign: 'center',
        background: theme.palette.color4,
        borderRadius: '5px',
        color: theme.palette.textColor2,
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            width: '100%',
            height: '30px',
            borderRadius: '5px',
            fontSize: '11px',
        }

    },

    button: {
        fontFamily: 'Montserrat',
        width: '80%',
        fontWeight: 'bold',
        height: '31px',
        background: theme.palette.color1,
        "&:hover": {
            background: theme.palette.color1,
        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            width: '100%',
            fontWeight: 'bold',
            height: '30px',
            fontFamily: 'Montserrat',
            fontSize: '15px',
        }
    }
}));

export default useStyles;
