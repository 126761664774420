import React, { useState } from 'react';
import Dropdown from "../../../utils/icons GM/dropdown.svg"
import useStyles from './style';

const Select = (props: any) => {
    const styleParams = {
        width: props?.width ?? 740,
    };
    const classes = useStyles(styleParams);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(-1);
    const [selectedLabel, setSelectedLabel] = useState();

    const handleChange = (value: any, label: any) => {
        props?.onChange(value)
        setSelectedValue(value);
        setSelectedLabel(label);
        setTimeout(() => toggleMenu(), 240);
    };
    const toggleMenu = () => setIsOpen(prev => !prev);
    return (
        <>
            <div className={classes.selectContainer}>
                <div className="button" onClick={toggleMenu}>
                    <img src={Dropdown} alt="DropDown" />
                    <span>
                        {selectedLabel ?? props.options?.find((el: any) => !el)?.label ?? 'SELECIONE'}
                    </span>

                </div>
                <div className={`menu ${isOpen ? 'isMenuOpen' : ''}`}>
                    {props.options
                        ?.map((el: any, key: any) => (
                            <div
                                key={key}
                                onClick={() => handleChange(el.value, el.label)}
                                className={`item ${selectedValue === el.value ? 'active' : ''}`}
                            >
                                {el.label}
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Select;
