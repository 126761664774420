import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
    selectContainer: {
        display: "flex",
        flexDirection: "column",
        wrap: "nowrap",
        fontSize: "16px",
        overflow: "hidden",
        lineHeight: "20px",
        color: theme.palette.color1,

        fontWeight: theme.typography.fontWeightBold,

        cursor: "pointer",

        "& > *": {
            borderRadius: ".3rem",
        },

        "& .button": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            width: "197px",
            height: "26px",
            backgroundColor: theme.palette.color1,
            color: theme.palette.textColor1,

            [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
                fontSize: "0.4em",
            },
            "& img": {
                [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
                    width: "20px",
                    marginLeft: "0px",
                },

                width: "20px",
                marginRight: "10px",
                marginLeft: "10px",
            },
            "& span": {
                flexGrow: 1,
                textAlign: "center",
                overflow: "hidden !important",
                whiteSpace: "nowrap",
                textOverflow: 'ellipsis',
            },

            "& .dropIcon": {
                borderLeft: "0.6em solid transparent",
                borderRight: "0.6em solid transparent",
                borderTop: `1.05em solid ${theme.palette.textColor1}`,
                transitionDuration: "0.5s",
                transitionProperty: "transform",

                "&.rotate": {
                    transform: "rotate(60deg)",
                },
            },
        },

        "& .menu": {
            position: "absolute",
            width: "70%",
            maxWidth: "400px",
            marginTop: "40px",
            maxHeight: 0,
            zIndex: 99,
            visibility: "hidden",
            backgroundColor: theme.palette.color5,
            color: theme.palette.textColor2,
            fontSize: "16px",
            transition: "max-height 0.15s ease-out, visibility 0.15s ease-out",
            boxShadow: "0px 0px 20px #888888",
            overflow: "hidden !important",
            whiteSpace: "nowrap",
            textOverflow: 'ellipsis',
            [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
                marginTop: "60px",
            },
            "& > .item": {
                display: "flex",
                padding: "0px 18px 0px 19px",
                alignItems: "center",
                height: "40px",
                transition: ".4s",

                "&.active": {
                    color: theme.palette.color1,
                    backgroundColor: theme.palette.color4,
                },
            },

            "&.isMenuOpen": {
                visibility: "visible",
                maxHeight: 420,
                overflowY: "auto",
                transition: "max-height 0.25s ease-in, visibility 0.25s ease-in",
            },
        },

        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: 25,
            "& .button": {
                padding: "20px 25px 20px 25px",
            },
            "& .menu": {
                "& > .item": {
                    padding: "20px 25px 20px 25px",
                },
            },
        },
    },
}));

export default useStyles;
