import { makeStyles } from '@material-ui/core/styles';
import image from '../../utils/background/laranja.png'
const useStyles = makeStyles((theme: any) => ({
    main: {
        backgroundColor: '#2a2a2a',
        backgroundImage: `url(${image})`,
        backgroundPosition: 'top',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',


        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            alignItems: 'flex-start',
        }
    },
    disabled: {
        opacity: 0.3,
        pointerEvents: 'none',
        userSelect: 'none',

        marginTop: '90px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBootton: '20px'

    },
    secondTitle: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column',
        marginBottom: '90px'
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        "& img": {
            width: '110px',
            height: '30px',
            marginTop: '43px',
        },
        "& img:nth-child(1)": {
            marginRight: '30px'
        },
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            "& img": {
                width: '150px',
                height: '37px',
                marginTop: '43px',
            },
            "& img:nth-child(1)": {
                marginLeft: '30px',
                marginRight: '40px'
            },
        }
    }, titles: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '15vh',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            marginTop: '138px',
        }

    },
    title: {
        maxWidth: '400px',
        fontSize: '34px',
        lineHeight: '43px',
        margin: "0px 30px 13px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '30px',
            maxWidth: '360px',
            lineHeight: '38px',
        }
    },
    subtitle: {
        maxWidth: '370px',
        fontSize: '16px',
        margin: "0px 30px 20px 30px",
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            fontSize: '14px',
            maxWidth: '400px',
            lineHeight: '18px',
        }
    },
    button: {
        marginTop: '10px',
        width: '253px',
        height: '34px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        backgroundColor: theme.palette.color1,
        fontSize: '20px',
        "&:hover": {
            backgroundColor: theme.palette.color1,
        },
        "& img": {
            width: '100%',
            alignSelf: 'center'
        }
    },
    container: {
        marginTop: '90px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginBootton: '20px'
    },
    select: {
        alignSelf: 'flex-start',

    },
    input: {
        border: 'none',
        marginTop: '20px',
        width: '100%',
        height: '42px',
        maxWidth: '430px',
        borderRadius: '10px',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            maxWidth: '730px',
        }

    },
    textarea: {
        border: 'none',
        marginTop: '20px',
        resize: 'none',
        height: '216px',
        width: '100%',
        maxWidth: '430px',
        borderRadius: '10px',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            maxWidth: '730px',
        }
    },
    content: {
        width: '95%',
        maxWidth: '430px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        [`@media (min-width:${theme.breakpoints.horizontal}px)`]: {
            maxWidth: '730px',
        }
    }
}));

export default useStyles;
