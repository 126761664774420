import React from 'react';
import { Route, Switch } from 'react-router-dom';


import Splash from '../pages/Splash';
import Access from '../pages/Access';
import Options from '../pages/Options';
import Create from '../pages/Create';

const routes = () => {
    return (
        <Switch>
            <Route path="/" component={Splash} exact />
            <Route path="/access" component={Access} />
            <Route path="/options" component={Options} />
            <Route path="/create" component={Create} />
        </Switch>
    )
}

export default routes