import React from 'react';

import { Button as MaterialButton } from '@material-ui/core';

import useStyles from './style';

const Button = (props: any) => {
    const styleParams = {
        fontSize: props.fontSize,
        padding: props.padding ?? '0.5em 1.5em 0.5em 1.5em',
        color: props.textColor,
        backgroundColor: props.bgColor,
        borderRadius: props.borderRadius,
        borderColor: props.borderColor,
    };
    const classes = useStyles(styleParams);

    return (
        <>
            <MaterialButton
                size={props.size}
                variant={props.variant}
                color={props.color}
                fullWidth={props.fullWidth}
                startIcon={props.startIcon}
                endIcon={props.endIcon}
                disabled={props.disabled}
                component={props.component}
                to={props.to}
                style={props.style}
                onClick={props.onClick}
                className={`${classes.root} ${props.className ?? ''}`}
            >
                <span style={{ lineHeight: 'normal' }}>{props.children}</span>
            </MaterialButton>
        </>
    );
};

export default Button;
