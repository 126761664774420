import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: any) => ({
    root: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: (styleParams: any) => styleParams?.fontSize ?? theme.typography.fontSize,
        color: (styleParams: any) => styleParams?.color ?? theme.palette.textColor1,
        /* backgroundColor: (styleParams: any) => styleParams?.backgroundColor ?? theme.palette.color1, */
        borderRadius: (styleParams: any) => styleParams?.borderRadius ?? '0.5rem',
        borderColor: (styleParams: any) => styleParams?.borderColor ?? theme.palette.color1,
        transition: theme.transitions.default,
        textTransform: 'none',

        '&:hover': {
            cursor: 'pointer',
            /* backgroundColor: (styleParams: any) => styleParams?.backgroundColor ?? theme.palette.color1, */
            filter: 'brightness(110%)',
        },

        '&:disabled': {
            borderColor: '#ccc',
            backgroundColor: theme.palette.color4,
        },
    },
}));

export default useStyles;
